import { Transform, Type } from 'class-transformer';
import {
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
  ValidateNested,
} from 'class-validator';
import { trim } from 'lodash';

import dtoBase from './Base.dto';
import { dtoResponse } from './Response.dto';
import 'reflect-metadata';

const exchangeNm = ['bithumb', 'upbit', 'coinone', 'top5', 'top3', 'full'];

// { symbol: 'BTC', currency: 'KRW', name: '비트코인', enName: 'Bitcoin', thumb: 'https://static.upbit.com/logos/BTC.png' }

const Order = [
  '',
  'marketcap',
  'price',
  'price1h',
  'volume',
  'price24h',
  'price7d',
  'coinNm',
];
const OrderType = ['', 'ASC', 'DESC'];
/**
 * 코인목록을 가져온다.
 *
 * @export
 * @class dtoCoinPrice
 * @extends {dtoBase}
 */
export class dtoCoinPrice extends dtoBase {
  /**
   * 거래소명
   *
   * @type {exchangeNm}
   * @memberof dtoCoinPrice
   */
  @IsIn(exchangeNm)
  @IsOptional()
  @IsString()
  exchangeNm: string = 'upbit';

  /**
   * 코인 심볼
   *
   * @type {string}
   * @memberof dtoCoinPrice
   */
  @MaxLength(50)
  @IsOptional()
  @IsString()
  coinSymbol: string;

  @IsIn(Order)
  @IsOptional()
  @IsString()
  order: string;

  @IsIn(OrderType)
  @IsOptional()
  @IsString()
  orderType: string;
}

/**
 *
 *
 * @export
 * @class dtoResCoinPrice
 */
export class dtoResCoinPrice extends dtoResponse {
  @Type(() => CoinPriceData)
  @ValidateNested({})
  datas: CoinPriceData[];

  @IsOptional()
  @IsNumber()
  totalCount: number;
}

export class CoinPriceData {
  /**
   * 거래소 명
   *
   * @type {string}
   * @memberof CoinPriceData
   */
  @IsString()
  @Transform(({ value }) => trim(value))
  exchangeName: string;

  /**
   * 코인심볼
   *
   * @type {string}
   * @memberof CoinPriceData
   */
  @IsString()
  @Transform(({ value }) => trim(value))
  coinSymbol: string;

  @IsString()
  @Transform(({ value }) => trim(value))
  coinNm: string;

  @IsString()
  marketCap: string;

  @IsString()
  premiumPercentage: string;

  @IsString()
  currentPrice: string;

  @IsString()
  percentChange1h: string;

  @IsString()
  percentChange24h: string;

  @IsString()
  percentChange7d: string;

  @IsString()
  volume24h: string;

  @IsString()
  logo: string;

  /**
   * 차트 이미지 링크
   *
   * @type {string}
   * @memberof CoinPriceData
   */
  @IsString()
  sparkLineUrl: string;

  @IsOptional()
  @IsString()
  browseUrl: string = '';
}
