/* 왼쪽 메인 대시보드 */

import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Utils } from '../apis/common/utils';
import { KosdaqResDto } from '../apis/dto/Kosdaq.dto';
import { KospiResDto } from '../apis/dto/Kospi.dto';
import { NasdaqResDto, NasdaqReqDto } from '../apis/dto/Nasdaq.dto';
import BTCDashboard from './BTCDashboard';
import ValueInfo from './ValueInfo';
import { plainToInstance } from 'class-transformer';
import { useLocation } from 'react-router-dom';

const util = new Utils();

const Main = (props) => {
  const location = useLocation();

  const { btcInfoData, usdInfoDAta, goldData, silverData } = props;
  const [kospiData, setKospiData] = useState<KospiResDto>();
  const [kosdaqData, setKosdaqData] = useState<KosdaqResDto>();
  const [nasdaqData, setNasdaqData] = useState<NasdaqResDto>();

  useEffect(() => {
    global.TICK.addListener('Tick5s', () => {
      fetchKOSPIData();
      fetchKOSDAQData();
      fetchNASDAQData();
    });
  }, []);

  const fetchKOSPIData = async () => {
    const resArray = new Array<KospiResDto>();

    try {
      const res = (await axios.get(
        'https://front.hs88.kr/market/kospi',
      )) as AxiosResponse;
      const result = Array.from(await util.regFilter(res.data)).splice(7);

      for (let i = 0; i <= result.length / 2; i += 7) {
        const model = new KospiResDto();
        model.date = result[i] as string;
        model.price = result[i + 4] as string;
        resArray.push(model);
      }
      const kospiValue = {
        date: resArray[1].date,
        price: resArray[1].price,
        rate: util.getRate(resArray[0].price, resArray[1].price),
      };
      setKospiData(kospiValue);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchKOSDAQData = async () => {
    const resArray = new Array<KosdaqResDto>();
    try {
      const res = (await axios.get(
        'https://front.hs88.kr/market/kosdaq',
      )) as AxiosResponse;
      const result = Array.from(await util.regFilter(res.data)).splice(7);

      for (let i = 0; i <= result.length / 2; i += 7) {
        const model = new KosdaqResDto();
        model.date = result[i] as string;
        model.price = result[i + 4] as string;
        resArray.push(model);
      }
      const kosdaqValue = {
        date: resArray[1].date,
        price: resArray[1].price,
        rate: util.getRate(resArray[0].price, resArray[1].price),
      };
      setKosdaqData(kosdaqValue);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchNASDAQData = async () => {
    try {
      const result = await axios.get('https://front.hs88.kr/market/nasdaq');
      const currentPrice = result.data.data.summaryData.CurrentPrice.value;
      const currentRate =
        result.data.data.summaryData.NetChangePercentageChange.value
          .split('/')[1]
          .replace('%', '');

      setNasdaqData({
        price: currentPrice,
        rate: currentRate,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="min-w-[1200px] flex  flex-col w-full min-h-screen max-h-[1080px] justify-start  px-[50px] pt-[60px] pb-[49px]  bg-[#262930]">
      <div className="header h-[60px] mb-[20px] flex flex-grow-0 justify-between items-start flex-shrink-0">
        <img src="/hs88_logo.svg" alt="" width={60} />
        {/* 로고, 날짜 */}
      </div>

      <BTCDashboard btcInfoData={btcInfoData} />
      <div className="flex flex-row  w-auto  ">
        {/* 1구역 */}
        <div className="flex flex-wrap flex-grow  basis-[50%] ">
          {/* TODO: 컴포넌트로 만들기 */}
          <ValueInfo
            title="BTC 점유율"
            value={btcInfoData?.btc_dominance}
            diff={btcInfoData?.btc_dominance_24h_change_percent}
          />

          <ValueInfo title="BTC 프리미엄" value={233472} diff={0.88} />

          {goldData && typeof usdInfoDAta !== 'undefined' && (
            <ValueInfo
              title="금"
              value={goldData?.price * usdInfoDAta.basePrice}
              diff={goldData.chp}
            />
          )}
          {silverData && typeof usdInfoDAta !== 'undefined' && (
            <ValueInfo
              title="은"
              value={silverData?.price * usdInfoDAta.basePrice}
              diff={silverData.chp}
            />
          )}
        </div>
        {/* 2구역 */}
        <div className="flex flex-col flex-grow  basis-[50%] mt-5 ">
          <div className="flex flex-col pt-5 border-t w-auto border-[#383d45]">
            <div className="label flex flex-row w-full mb-5  ">
              <div className="text-[#848e9c] basis-[50%]">이름</div>
              <div className="text-[#848e9c]  basis-[30%] text-right">
                현재가
              </div>
              <div className="text-[#848e9c]  basis-[20%] text-right">
                전일대비
              </div>
            </div>
            <div className="value flex flex-col">
              <div className="flex flex-row text-xl mb-5 ">
                <span className="text-white font-light  basis-[50%]">
                  KOSPI
                </span>
                <span className="font-semibold text-white text-right  basis-[30%]">
                  {Number(kospiData?.price).toLocaleString()}
                </span>
                <span
                  className={`${
                    Number(kospiData?.rate) > 0
                      ? ' font-semibold basis-[20%] text-right text-[#1ecb81]'
                      : ' font-semibold basis-[20%] text-right text-[#f6465d]'
                  }`}
                >
                  {kospiData?.rate}%
                </span>
              </div>
              <div className="flex flex-row text-xl mb-5 ">
                <span className="text-white font-light basis-[50%]">
                  KOSDAQ
                </span>
                <span className="font-semibold basis-[30%] text-right text-white  ">
                  {Number(kosdaqData?.price).toLocaleString()}
                </span>
                <span
                  className={`${
                    Number(kosdaqData?.rate) > 0
                      ? ' font-semibold basis-[20%] text-right text-[#1ecb81]'
                      : ' font-semibold basis-[20%] text-right text-[#f6465d]'
                  }`}
                >
                  {kosdaqData?.rate}%
                </span>
              </div>
              <div className="flex flex-row text-xl mb-5 ">
                <span className="text-white font-light basis-[50%]">
                  NASDAQ 100
                </span>
                <span className="font-semibold basis-[30%] text-white  text-right ">
                  {nasdaqData?.price}
                </span>
                <span
                  className={`${
                    Number(nasdaqData?.rate) > 0
                      ? ' font-semibold basis-[20%] text-right text-[#1ecb81]'
                      : ' font-semibold basis-[20%] text-right text-[#f6465d]'
                  }`}
                >
                  {nasdaqData?.rate}%
                </span>
              </div>
            </div>
          </div>
          <div className=" border-t border-[#383d45] pt-5">
            <div className="flex flex-col">
              <div className="label flex flex-row w-full mb-5 ">
                <div className="text-[#848e9c] basis-[50%]">환율</div>
                <div className="text-[#848e9c] basis-[30%] text-right">
                  현재가
                </div>
                <div className="text-[#848e9c]  basis-[20%] text-right">
                  전일대비
                </div>
              </div>
              {typeof usdInfoDAta !== 'undefined' && (
                <div className="value flex flex-row">
                  <div className="flex flex-row text-xl w-full">
                    <span className="text-white font-light basis-[50%]">
                      USD/KRW
                    </span>
                    <span className="font-semibold basis-[30%] text-right text-white  ">
                      {usdInfoDAta?.basePrice}
                    </span>
                    <span
                      className={`${
                        usdInfoDAta?.change === 'RISE'
                          ? ' font-semibold basis-[20%] text-right text-[#1ecb81]'
                          : ' font-semibold basis-[20%] text-right text-[#f6465d]'
                      }`}
                    >
                      {(usdInfoDAta?.changeRate * 100).toFixed(2)}%
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
