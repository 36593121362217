import { createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import { ICoinGecko } from '../../websockets/ICoin';

export interface IMarketInitialState {
  marketCoinData: any;
  marketCoinPriceData: any;
  coingeckoList: ICoinGecko;
}

export const marketInitialState: IMarketInitialState = {
  marketCoinData: null,
  marketCoinPriceData: null,
  coingeckoList: {
    coins: [],
  },
};

const marketSlice = createSlice({
  name: 'market',
  initialState: marketInitialState,
  reducers: {
    LOAD_MARKET_COIN_LIST_SUCCESS: (
      state = marketInitialState,
      action: any,
    ) => {
      state.marketCoinData = action.payload;
    },
    LOAD_MARKET_COIN_PRICE_LIST_SUCCESS: (
      state = marketInitialState,
      action: any,
    ) => {
      state.marketCoinPriceData = action.payload;
    },
    LOAD_MARKET_COIN_INFO_LIST_SUCCESS: (
      state = marketInitialState,
      action: any,
    ) => {
      state.coingeckoList = action.payload;
    },
  },
});

export const {
  LOAD_MARKET_COIN_LIST_SUCCESS,
  LOAD_MARKET_COIN_PRICE_LIST_SUCCESS,
  LOAD_MARKET_COIN_INFO_LIST_SUCCESS,
} = marketSlice.actions;
export default marketSlice.reducer;
