const ValueInfo = (props) => {
  const { title, value, diff } = props;

  if (title === '금' || title === '은' || title === 'BTC 프리미엄') {
    return (
      <div className="bg-[#1e1e25] flex justify-start text-left flex-col px-5 py-7 rounded-[0.625rem] w-[45%] h-40 flex-grow flex-shrink-0 mt-5 mr-5">
        <div className="text-[#848e9c] text-base mb-3">{title}</div>
        <div className="font-semibold text-white text-2xl mb-3">
          {Number(value.toFixed(1)).toLocaleString()}
        </div>
        {diff >= 0 && (
          <div className="font-semibold  text-2xl text-[#1ecb81] ">{diff}%</div>
        )}
        {diff < 0 && (
          <div className="font-semibold  text-2xl text-[#ff2727]">{diff}%</div>
        )}
      </div>
    );
  } else {
    return (
      <div className="bg-[#1e1e25] flex justify-start text-left flex-col px-5 py-7 rounded-[0.625rem] w-[45%] h-40 flex-grow flex-shrink-0 mt-5 mr-5">
        <div className="text-[#848e9c] text-base mb-3">{title}</div>
        <div className="font-semibold text-white text-2xl mb-3">{value}%</div>
        {diff >= 0 && (
          <div className="font-semibold  text-2xl text-[#1ecb81] ">{diff}%</div>
        )}
        {diff < 0 && (
          <div className="font-semibold  text-2xl text-[#ff2727]">{diff}%</div>
        )}
      </div>
    );
  }
};

export default ValueInfo;
