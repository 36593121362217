import { IsString } from 'class-validator';

export class MetalReqDto {
  @IsString()
  symbol: 'XAU' | 'XAG' = 'XAG';

  @IsString()
  currency: string = 'KRW';
}

export class MetalResDto {
  @IsString()
  metal: string;

  @IsString()
  currency: string = 'KRW';

  @IsString()
  price: string;

  @IsString()
  chp: string;

  @IsString()
  ch: string;
}
