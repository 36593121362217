const Mou = () => {
  return (
    <div className="w-full h-screen bg-cover bg-center bg-no-repeat bg-[url('https://s3.ap-northeast-2.amazonaws.com/coala.co.kr/files/huobixhs88.png')]">
      {/* <img
        src="https://s3.ap-northeast-2.amazonaws.com/coala.co.kr/files/mou.png"
        width={1920}
        height={1080}
      /> */}
    </div>
  );
};

export default Mou;
