export class Utils {
  regFilter(data: any): Promise<any> {
    const regexp = /\t|\n|[\{\}\[\]\/?;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
    return data.trim().replaceAll(regexp, '').replaceAll(' ', '').split(',');
  }

  getDate(i: number): string {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const date = new Date().getDate() + i;

    return (
      year.toString() +
      (month < 9 ? '0' + month.toString() : month.toString()) +
      (date < 9 ? '0' + date.toString() : date.toString())
    );
  }

  /**
   * 등락률 계산
   * ((오늘 종가 - 어제 종가) / 어제 종가) * 100
   */
  getRate(before: string, today: string): string {
    before = before.replaceAll(',', '');
    today = today.replaceAll(',', '');
    return (
      ((parseFloat(today) - parseFloat(before)) / parseFloat(before)) *
      100
    ).toFixed(2);
  }
}
