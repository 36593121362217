import axios, { Method } from 'axios';
import { dtoHeaders } from '../dto/Headers.dto';
import { dtoResponse } from '../dto/Response.dto';

const coalaCaller = axios.create({
  baseURL: `https://coala.co.kr/api/v1`,
  method: 'POST',
  withCredentials: true,
});

export const codexCaller = axios.create({
  baseURL: `https://coala.co.kr/market/coincodex`,
  method: 'GET',
  withCredentials: true,
});

// export const codexCaller = axios.create({
//   baseURL: "https://coincodex.com/api/coincodex/get_metadata",
//   method: "GET",
//   withCredentials: true,
// });

export class ApiCaller {
  async generateCSRHeader(path: string): Promise<dtoHeaders> {
    const result = new dtoHeaders();

    // result.authorization =
    //   (window.localStorage.getItem('auth') as string) || ''; //session.id;
    result.path = `${path}`;
    // delete result.ip;
    // result.ip = (req.headers['cf-connecting-ip'] as string) || '0.0.0.0';
    // result.country = (req.headers['cf-ipcountry'] as string) || 'KR';
    // result.sid = req.session.id;

    return result;
  }

  // 공통
  async execute<U, T extends dtoResponse>(
    url: string,
    header: dtoHeaders,
    data: U,
    method: Method = 'POST',
  ): Promise<T> {
    let response: any;
    let result: T;

    try {
      const caller = coalaCaller;

      response = await caller({
        data: { ...data },
        url,
        headers: { ...header },
        method,
      });
      result = response.data;

      // try {
      //   const reissue = await caller({
      //     data: { userId: authdecode.userId },
      //     url: 'Reissue',
      //     headers: { ...header },
      //     method,
      //   });
      //   // window.localStorage.setItem('auth', reissue.data.datas.authorization);
      //   console.log(reissue);
      // } catch (error) {
      //   window.localStorage.removeItem('auth');
      //   console.error(error);
      // }
    } catch (error) {
      console.error(error);
      throw error;
    }

    return result;
  }
}

export class BTCInfoCaller {
  async generateCSRHeader(path: string): Promise<dtoHeaders> {
    const result = new dtoHeaders();

    // result.authorization =
    //   (window.localStorage.getItem('auth') as string) || ''; //session.id;
    result.path = `${path}`;
    // delete result.ip;
    // result.ip = (req.headers['cf-connecting-ip'] as string) || '0.0.0.0';
    // result.country = (req.headers['cf-ipcountry'] as string) || 'KR';
    // result.sid = req.session.id;

    return result;
  }
  async execute<U, T extends dtoResponse>(
    url: string,
    header: dtoHeaders,
    data: U,
    method: Method = 'POST',
  ): Promise<T> {
    let response: any;
    let result: T;

    try {
      const caller = codexCaller;

      response = await caller({
        url,
        headers: { ...header },
        method,
      });
      result = response.data;

      // try {
      //   const reissue = await caller({
      //     data: { userId: authdecode.userId },
      //     url: 'Reissue',
      //     headers: { ...header },
      //     method,
      //   });
      //   // window.localStorage.setItem('auth', reissue.data.datas.authorization);
      //   console.log(reissue);
      // } catch (error) {
      //   window.localStorage.removeItem('auth');
      //   console.error(error);
      // }
    } catch (error) {
      console.error(error);
      throw error;
    }

    return result;
  }
}
