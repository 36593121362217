import { IsNumber, IsString } from 'class-validator';

export class KospiReqDto {
  @IsString()
  symbol: string;

  @IsNumber()
  requestType: number;

  @IsNumber()
  count: number;

  @IsString()
  startTime: string;

  @IsString()
  endTime: string;

  @IsString()
  timeframe: string;
}

export class KospiResDto {
  @IsString()
  date: string;

  // 종가만 사용
  @IsString()
  price: string;

  @IsString()
  rate: string;
}
