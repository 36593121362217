import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BTCInfoCaller } from '../lib/apiCaller';
import Main from '../components/Main';
import Sub from '../components/Sub';
import axios, { AxiosResponse } from 'axios';
import { useAppDispatch } from '../store/config';
import * as btcActions from '../store/modules/btcInfo';
import * as marketActions from '../store/modules//market';
import { MetalReqDto } from '../apis/dto/Metal.dto';
import { dtoResMetalPrice } from '../dto/MetalPrice.dto';
import * as Service from '../apis/services/index';

const codexSender = new BTCInfoCaller();

const Index = () => {
  const location = useLocation();
  const [btcInfoData, setBtcInfoData] = useState<any>();
  const [usdInfoDAta, setUsdInfoData] = useState();
  const [silverData, setSilverData] = useState<dtoResMetalPrice>();
  const [goldData, setGoldData] = useState<dtoResMetalPrice>();

  const loadBTCInfoAPI = async () => {
    const header = await codexSender.generateCSRHeader(location.pathname);
    const res = await codexSender.execute('/', header, {}, 'GET');
    setBtcInfoData(res);
  };

  const dispatch = useAppDispatch();

  const loadCoinInfoListAPI = async () => {
    const res = await axios.get(
      'https://api.coingecko.com/api/v3/search?locale=ko',
    );
    dispatch(marketActions.LOAD_MARKET_COIN_INFO_LIST_SUCCESS(res.data));
  };

  const exchangeRateAPI = async () => {
    const result = await axios.get(
      // 'https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD',
      'https://front.hs88.kr/exchange',
    );
    dispatch(btcActions.LOAD_EXCHANGE_RATE_SUCCESS(result.data[0]));
  };

  const BTCInfoAPI = async () => {
    const result = await axios.get('https://coala.co.kr/market/coincodex');
    dispatch(btcActions.LOAD_BTC_INFO_SUCCESS(result.data));
  };

  const fetchMetalData = async (type: 'silver' | 'gold') => {
    const metalApi = new Service.Metal();

    const reqDto = new MetalReqDto();
    reqDto.currency = 'USD';
    if (type === 'silver') {
      reqDto.symbol = 'XAG';
    } else {
      reqDto.symbol = 'XAU';
    }
    try {
      const res = await metalApi.execute(reqDto);
      if (type === 'silver') {
        setSilverData(res);
      } else {
        reqDto.symbol = 'XAU';
        setGoldData(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadCoinInfoListAPI();
    exchangeRateAPI();
    BTCInfoAPI();
    fetchMetalData('silver');
    fetchMetalData('gold');
  }, []);

  const loadUSDRate = async () => {
    try {
      const result = await axios.get('https://front.hs88.kr/exchange');
      setUsdInfoData(result.data[0]);
    } catch (error) {}
  };

  const injectScript = ({ src, dataSet }) => {
    const script = document.createElement('script');
    script.src = src;
    script.setAttribute('defer', '');
    script.setAttribute(
      Object.keys(dataSet)[0],
      Object.values(dataSet)[0] as string,
    );
    document.body.appendChild(script);
  };

  useEffect(() => {
    global.TICK.addListener('Tick3s', () => {
      loadBTCInfoAPI();
    });

    global.TICK.addListener('Tick5s', () => {
      loadUSDRate();
    });
    global.TICK.addListener('Tick3h', () => {
      fetchMetalData('silver');
      fetchMetalData('gold');
    });

    injectScript({
      src: 'https://static.cloudflareinsights.com/beacon.min.js',
      dataSet: { 'cf-beacon': '{"token": "faab642947364418bad5adbcbeea65ed"}' },
    });
    injectScript({
      src: 'https://static.cloudflareinsights.com/beacon.min.js',
      dataSet: { 'cf-beacon': '{"token": "887794216d2b43499a71dbef19ab484f"}' },
    });
  }, []);

  return (
    <div className="flex flex-row min-h-screen max-h-[1080px]">
      <Main
        btcInfoData={btcInfoData}
        usdInfoDAta={usdInfoDAta}
        goldData={goldData}
        silverData={silverData}
      />
      <Sub />
    </div>
  );
};

export default Index;
