import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const RealTimeClock = () => {
  const [clock, setClock] = useState<string>();

  useEffect(() => {
    global.TICK.addListener('Tick1s', (e) => {
      setClock(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    });
  }, []);

  return (
    <span className="text-white">
      <div className="text-xl">{clock}</div>
    </span>
  );
};

export default RealTimeClock;
