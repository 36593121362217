import {
  CoinPriceData,
  dtoCoinPrice,
  dtoResCoinPrice,
} from '../dto/CoinPrice.dto';
import { ApiCaller, BTCInfoCaller } from '../lib/apiCaller';
import { useEffect, useState } from 'react';
import TradingViewChart from './TradingViewChart';
import { head } from 'lodash';
import { getExchangePrice, getKoreanNumber, roundFnc } from '../lib/tools';

const Sender = new ApiCaller();

const BTCDashboard = (props) => {
  const { btcInfoData } = props;
  const [currentData, setCurrentData] = useState<CoinPriceData>();

  const renderBTCData = async () => {
    const header = await Sender.generateCSRHeader('/');
    header.count = '1';
    const reqData = new dtoCoinPrice();
    reqData.exchangeNm = 'upbit';
    reqData.coinSymbol = 'BTC';
    try {
      const BTCData = await Sender.execute<dtoCoinPrice, dtoResCoinPrice>(
        'CoinPrice',
        header,
        reqData,
      );
      setCurrentData(head(BTCData.datas));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    global.TICK.addListener('Tick3s', () => {
      renderBTCData();
    });
  }, []);

  return (
    <div className="bg-[#1e1e25] flex flex-col p-[30px] min-h-[530px] rounded-[0.625rem]  ">
      <div className="flex flex-row justify-between  mb-[20px]">
        <div className="flex flex-col">
          <div className="text-white font-semibold flex items-center">
            <img
              src="/btc_white.svg"
              width={24}
              height={24}
              alt=""
              className="mr-2"
            />
            <span className="mr-2">비트코인</span>
            <span>BTC</span>
          </div>
          <div className="text-white font-semibold text-[3rem]">
            <span> {Number(currentData?.currentPrice).toLocaleString()}</span>
            <span className="text-base font-normal"> KRW</span>
          </div>
          {/* TODO: 마이너스면 빨간색  */}
          <div className=" font-semibold text-xl flex flex-row">
            <div className="flcttVolume flex items-center">
              {parseFloat(currentData?.percentChange24h as string) > 0 ? (
                <div className="plus flex items-center">
                  <span style={{ color: '#17a37f' }}>
                    {getExchangePrice(
                      currentData?.percentChange24h as string,
                      currentData?.currentPrice as string,
                    )}
                  </span>
                </div>
              ) : (
                <div className="flex items-center">
                  <span
                    className="flex items-center"
                    style={{ color: '#e12343', display: 'flex' }}
                  >
                    {getExchangePrice(
                      currentData?.percentChange24h as string,
                      currentData?.currentPrice as string,
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="ml-3">
              {Number(currentData?.percentChange24h) > 0 ? (
                <div className="plus">
                  <span className="text-[#17a37f]">
                    +{roundFnc(currentData?.percentChange24h)}%
                  </span>
                </div>
              ) : (
                <div className="minus">
                  <span className="text-[#e12343]">
                    {roundFnc(currentData?.percentChange24h)}%
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row flex-grow ">
            <div className="flex flex-col flex-grow mr-5">
              <span className="text-[#848e9c]">거래량 (24h)</span>
              <span className="text-white">
                {getKoreanNumber(btcInfoData?.total_volume)}
              </span>
            </div>
            <div className="flex flex-col flex-grow">
              <span className="text-[#848e9c]">고가</span>
              <span className="text-white">82,700,000</span>
            </div>
          </div>
          <div className="flex flex-row flex-grow">
            <div className="flex flex-col mr-1">
              <span className="text-[#848e9c]">거래대금 (24h)</span>
              <span className="text-white">
                {getKoreanNumber(
                  parseFloat(currentData?.volume24h as string),
                  true,
                )}
              </span>
            </div>
            <div className="flex flex-col flex-grow">
              <span className="text-[#848e9c]">저가</span>
              <span className="text-white">
                {Number(currentData?.currentPrice as string).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full m-auto">
        <TradingViewChart
          // width={1040}
          height={'100%'}
          symbol={`UPBIT:BTCKRW`}
          hide_top_toolbar={true}
          hide_side_toolbar={true}
          allow_symbol_change={false}
          hide_legend={true}
        />
      </div>
    </div>
  );
};

export default BTCDashboard;
