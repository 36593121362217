import { IsIP, IsOptional, IsString } from 'class-validator';

import dtoBase from './Base.dto';

export class dtoHeaders extends dtoBase {
  @IsString()
  name = 'dtoHeaders';
  /**
   * JWT
   *
   * @type {string}
   * @memberof dtoHeaders
   */
  @IsOptional()
  @IsString()
  authorization = '';

  /**
   * 호출 페이지 경로
   *
   * @type {string}
   * @memberof dtoHeaders
   */
  @IsString()
  path: string;

  /**
   * 여러개 일경우 페이지
   *
   * @type {number}
   * @memberof dtoHeaders
   */
  @IsOptional()
  @IsString()
  page = '0';

  /**
   * 페이지당 아이템 갯수
   *
   * @type {number}
   * @memberof dtoHeaders
   */
  @IsOptional()
  @IsString()
  count = '10';
}
/**
 * 헤더 dto 프론트용
 *
 * @export
 * @class dtoHeaders
 * @extends {Base}
 */
export class dtoSSRHeaders extends dtoHeaders {
  @IsString()
  name = 'dtoSSRHeaders';
  /**
   * 유저 에이전트
   *
   * @type {string}
   * @memberof dtoHeaders
   */
  @IsString()
  'user-agent': string;

  /**
   * 유저 아이피(프론트 엔드에서 참조)
   * 헤더의 cf-connecting-ip 값을 넣어주세요 - local 실행일때는 예외
   *
   * @type {string}
   * @memberof dtoHeaders
   */
  @IsOptional()
  @IsIP()
  ip: string;

  /**
   * 쿠키의 connect.sid를 널어주세요
   *
   * @type {string}
   * @memberof dtoHeaders
   */
  @IsOptional()
  @IsString()
  sid: string;

  /**
   * 헤더의 cf-ipcountry 값을 넣어주세요 - local 실행일때는 예외
   *
   * @type {string}
   * @memberof dtoHeaders
   */
  @IsOptional()
  @IsString()
  country: string;
}

// 클라우드 플레어를 통애서 들어올 경우 아래의 데이터가 프론트에 들어옵니다.
// {
//   'access-control-allow-origin': '*',
//   'access-control-allow-methods': 'GET, POST, OPTIONS, PUT, DELETE',
//   'access-control-allow-headers': 'X-Requested-With,Accept,Content-Type, Origin',
//   'x-forwarded-for': '211.192.248.130, 162.158.5.208, 100,100,100,100',
//   'x-real-ip': '162.158.5.208',
//   host: 'dona.hs88.kr',
//   connection: 'upgrade',
//   'accept-encoding': 'gzip',
//   'cf-ipcountry': 'KR',
//   'cf-ray': '69dd9f80cb11ae9d-KIX',
//   'x-forwarded-proto': 'https',
//   'cf-visitor': '{"scheme":"https"}',
//   'sec-ch-ua': '"Chromium";v="94", "Google Chrome";v="94", ";Not A Brand";v="99"',
//   'cache-control': 'no-cache',
//   'content-type': 'application/json; charset=utf-8',
//   'sec-ch-ua-mobile': '?0',
//   'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36',
//   'sec-ch-ua-platform': '"macOS"',
//   'postman-token': '32a479bf-3c36-b551-0c85-34570d06bf22',
//   accept: '*/*',
//   'sec-fetch-site': 'none',
//   'sec-fetch-mode': 'cors',
//   'sec-fetch-dest': 'empty',
//   'accept-language': 'ko-KR,ko;q=0.9,en-US;q=0.8,en;q=0.7',
//   'cf-connecting-ip': '211.192.248.130',
//   'cdn-loop': 'cloudflare'
// }
