import { createSlice } from '@reduxjs/toolkit';
import bigDecimal from 'js-big-decimal';
export interface IBTCInfoInitialState {
  exchangeInfo: {
    basePrice: bigDecimal;
    changeRate: number;
    changePrice: number;
  };
  btcInfo: {
    btc_dominance: number;
    btc_dominance_24h_change_percent: number;
    total_market_cap: number;
    total_market_cap_24h_change_percent: number;
    total_volume: number;
    total_volume_24h_change_percent: number;
  };
}
export const btcInfoInitialState: IBTCInfoInitialState = {
  exchangeInfo: {
    basePrice: new bigDecimal(0),
    changeRate: 0,
    changePrice: 0,
  },
  btcInfo: {
    btc_dominance: 0,
    btc_dominance_24h_change_percent: 0,
    total_market_cap: 0,
    total_market_cap_24h_change_percent: 0,
    total_volume: 0,
    total_volume_24h_change_percent: 0,
  },
};

const btcSlice = createSlice({
  name: 'btc',
  initialState: btcInfoInitialState,
  reducers: {
    LOAD_EXCHANGE_RATE_SUCCESS: (state = btcInfoInitialState, action: any) => {
      state.exchangeInfo = action.payload;
      state.exchangeInfo.basePrice = action.payload.basePrice;
    },
    LOAD_BTC_INFO_SUCCESS: (state, action) => {
      state.btcInfo = action.payload;
    },
  },
});

export const { LOAD_EXCHANGE_RATE_SUCCESS, LOAD_BTC_INFO_SUCCESS } =
  btcSlice.actions;
export default btcSlice.reducer;
