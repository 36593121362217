import BigNumber from 'bignumber.js';
import bigDecimal from 'js-big-decimal';

export const getExchangePrice = (percent: string, price: string) => {
  const p1 = new BigNumber(percent);
  const p2 = new BigNumber(price);
  const p3 = new BigNumber(0.01);

  const result = p1.multipliedBy(p2).multipliedBy(p3);

  return Number(result.toFixed(3)).toLocaleString();
};

export const roundFnc = (num: any) => {
  const m = Number(num).toFixed(2);
  // const m = Number((Math.abs(num) * 100).toPrecision(15));
  return m;
};

export const getKoreanNumber = (
  number: number,
  onlyBiggest?: boolean,
): React.ReactNode => {
  const koreanUnits = ['', '만', '억', '조', '경'];
  const unit = 10000;
  let index = 0;
  let division = Math.pow(unit, index);

  while (Math.floor(number / (division * unit)) > 0) {
    division = Math.pow(unit, ++index);
  }

  const mod = (number / division).toLocaleString('en-us', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const result = `${mod}${koreanUnits[index]}`;
  return result;
};
export function calcRatio(base: number, compare: number) {
  const calc = base / compare;
  return calc < 0 ? (1 - calc) * -1 : calc - 1;
}
