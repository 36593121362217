import { MetalReqDto, MetalResDto } from '../dto/Metal.dto';
import axios from 'axios';

export class Metal {
  private baseUrl: string;
  private accessToken: string;

  constructor() {
    this.baseUrl = process.env.REACT_APP_GoldAPI_baseUrl as string;
    this.accessToken = process.env.REACT_APP_GoldAPI_accessToken as string;
  }

  async execute(data: MetalReqDto): Promise<MetalResDto> {
    let result;

    try {
      const url = `${this.baseUrl}${data.symbol}/${data.currency}`;

      result = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': this.accessToken,
        },
      });

      /**
       * metal : 금속 종류
       * currency : 표시 통화
       * price : 시세
       * chp : 변동 Percentage
       * ch : 변동 금액
       */
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}
