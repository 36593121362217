import { EventEmitter } from 'events';
import { scheduleJob } from 'node-schedule';

export class Tick extends EventEmitter {
  static Tick1s: string = 'Tick1s';
  static Tick5s: string = 'Tick5s';
  static Tick3s: string = 'Tick3s';
  static Tick3h: string = 'Tick3h';

  constructor() {
    super();

    this.init();
  }

  private init() {
    scheduleJob('*/1 * * * * *', async (_fireDate) => {
      this.emit(Tick.Tick1s);
    });

    scheduleJob('*/5 * * * * *', async (_fireDate) => {
      this.emit(Tick.Tick5s);
    });

    scheduleJob('*/3 * * * * *', async (_fireDate) => {
      this.emit(Tick.Tick3s);
    });

    scheduleJob('*/* * 3 * * *', async (_fireDate) => {
      this.emit(Tick.Tick3h);
    });
  }
}
