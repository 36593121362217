/* 왼쪽 메인 대시보드 */

import React from 'react';
import CoinInfoCard from './CoinInfo';
import RealTimeClock from './Clock';
const Sub = () => {
  return (
    <div className="min-w-[720px] flex flex-col  min-h-[1080px] px-[50px] pt-[60px] pb-[49px] bg-[rgb(30,30,37)]">
      <div className="header h-[80px]  flex flex-shrink-0 flex-grow-0 justify-between items-center">
        <img src="/coala_logo.svg" width={150} height={65} alt="" />
        {/* 로고, 날짜 */}

        <RealTimeClock />
        {/* <span className="text-white">
          {dayjs().format("YYYY-MM-DD  HH:mm:ss")}
        </span> */}
      </div>
      <div className=" w-full">
        <div className="h-[300px] w-full">
          <CoinInfoCard coinSymbol="ETH" range={'1D'} />
        </div>
        <div className=" h-[600px] w-full flex flex-wrap mt-5 pb-5  flex-shrink-1 flex-grow-0 justify-between ">
          <div className=" h-[180px] basis-[48%] mb-10">
            <CoinInfoCard coinSymbol="XRP" range={'1D'} />
          </div>
          <div className=" h-[180px]  basis-[48%] mb-10">
            <CoinInfoCard coinSymbol="SOL" range={'1D'} />
          </div>
          <div className=" h-[180px]  basis-[48%]  mt-[30px]">
            <CoinInfoCard coinSymbol="USDT" range={'5d'} />
          </div>
          <div className=" h-[180px]  basis-[48%]  mt-[30px]">
            <CoinInfoCard coinSymbol="ADA" range={'1D'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sub;
