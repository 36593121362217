import React, { Component, PureComponent } from 'react';

declare const TradingView: any;

export type widgetProps = {
  width?: string | number;
  height?: string | number;
  autosize?: boolean;
  symbol?: string;
  interval?: string;
  range?: string;
  timezone?: string;
  theme?: string;
  style?: string;
  locale?: string;
  toolbar_bg?: string;
  hide_top_toolbar?: boolean;
  hide_side_toolbar?: boolean;
  withdateranges?: boolean;
  save_image?: boolean;
  enable_publishing?: boolean;
  allow_symbol_change?: boolean;
  hide_legend?: boolean;
  container_id?: string;
};

class TradingViewChart extends PureComponent<widgetProps> {
  containerId = 'advanced-chart-widget-container';
  ref: { current: HTMLDivElement | null } = React.createRef();

  componentDidMount() {
    if (this.props.container_id) {
      this.containerId = this.props.container_id;
    }
    if (this.ref.current) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;

      script.onload = () => {
        if (this.ref.current) {
          if (
            this.ref.current.children.length === 1 &&
            typeof TradingView !== 'undefined'
          ) {
            new TradingView.widget({
              width: '100%',
              height: '100%',
              symbol: 'BITMEX:XBTUSD',
              interval: '240',
              range: '1D',
              timezone: 'Asia/Seoul',
              theme: 'dark',
              style: '1',
              locale: 'kr',
              hide_side_toolbar: true,
              enable_publishing: false,
              allow_symbol_change: false,
              save_image: false,
              hide_legend: true,
              container_id: this.containerId,
              ...this.props,
            });
          }
        }
      };
      this.ref.current.appendChild(script);
    }
  }

  componentWillUnmount() {
    if (this.ref.current) {
      while (this.ref.current.firstChild) {
        this.ref.current.removeChild(this.ref.current.firstChild);
      }
    }
  }

  render() {
    return <div className="h-full" id={this.containerId} ref={this.ref} />;
  }
}

export default TradingViewChart;
