import btc from './btcInfo';
import market from './market';
import { combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  btc,
  market,
});

export default rootReducer;
