import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { head } from 'lodash';
import { useEffect, useState } from 'react';
import { ApiCaller } from '../lib/apiCaller';
import {
  CoinPriceData,
  dtoCoinPrice,
  dtoResCoinPrice,
} from '../dto/CoinPrice.dto';
import { getExchangePrice, roundFnc } from '../lib/tools';
import { useLocation } from 'react-router-dom';

const Sender = new ApiCaller();

interface IProps {
  coinSymbol: string;
  range: '1D' | '1m' | '1M' | '5d' | '12m' | '60m' | '1d' | '5D';
}
const CoinInfoCard = (props: IProps) => {
  const location = useLocation();
  const { coinSymbol } = props;

  const [coinData, setCoinData] = useState<CoinPriceData>();
  /* 코인한글이름, 영문이름, 코인심볼, 현재 가격 */
  const renderCoinData = async (coinSymbol: string) => {
    const header = await Sender.generateCSRHeader(location.pathname);
    header.count = '1';
    const reqData = new dtoCoinPrice();
    reqData.exchangeNm = 'upbit';
    reqData.coinSymbol = coinSymbol;
    try {
      const res = await Sender.execute<dtoCoinPrice, dtoResCoinPrice>(
        'CoinPrice',
        header,
        reqData,
      );
      setCoinData(head(res.datas));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    global.TICK.addListener('Tick3s', () => {
      renderCoinData(coinSymbol);
    });
  }, []);

  if (typeof coinData !== 'undefined') {
    return (
      <div
        id="coininfoCard"
        className="bg-[#262930] rounded-[0.625rem]  p-5 text-white"
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <span>{coinData?.coinNm}</span>
            <span>{coinData.coinSymbol}</span>
          </div>
          <div>
            <img width={24} height={24} src={coinData.logo} alt="" />
          </div>
        </div>

        <div className="flex justify-end items-end">
          <span className="text-right mr-2 text-2xl">
            {Number(coinData.currentPrice).toLocaleString()}
          </span>
          <span className="text-base font-normal">KRW</span>
        </div>

        <div className=" font-semibold text-xl flex flex-row justify-end items-end">
          <div className="flcttVolume flex items-center">
            {parseFloat(coinData.percentChange24h as string) > 0 ? (
              <div className="plus flex items-center">
                <span className="text-[#1ecb81]">
                  {getExchangePrice(
                    coinData.percentChange24h as string,
                    coinData.currentPrice as string,
                  )}
                </span>
              </div>
            ) : (
              <div className="flex items-center">
                <span className="flex items-center text-[#e12343]">
                  {getExchangePrice(
                    coinData.percentChange24h as string,
                    coinData.currentPrice as string,
                  )}
                </span>
              </div>
            )}
          </div>

          <div className="ml-3">
            {Number(coinData.percentChange24h) > 0 ? (
              <div className="plus">
                <span className="text-[#1ecb81]">
                  +{roundFnc(coinData.percentChange24h)}%
                </span>
              </div>
            ) : (
              <div className="minus">
                <span className="text-[#e12343]">
                  {roundFnc(coinData.percentChange24h)}%
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="h-[60%] m-auto w-full">
          {props.coinSymbol && (
            <TradingViewWidget
              symbol={
                coinData.coinSymbol === 'USDT'
                  ? `BINANCEUS:${coinData.coinSymbol}USD`
                  : `UPBIT:${coinData.coinSymbol}KRW`
              }
              theme={Themes.DARK}
              interval="D"
              locale="kr"
              timezone="Asia/Seoul"
              hide_top_toolbar={true}
              hide_side_toolbar={true}
              allow_symbol_change={false}
              hide_legend={true}
              autosize
              range={props.range}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CoinInfoCard;
