import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  IsBoolean,
  IsOptional,
  IsString,
  IsNumber,
  IsNotEmpty,
} from 'class-validator';
dayjs.extend(utc);

/**
 * 응답값
 *
 * @export
 * @class dtoResponse
 * @template ResData
 */
export class dtoResponse {
  /**
   * 성공여부
   *
   * @type {boolean}
   * @memberof dtoResponse
   */
  @IsNotEmpty()
  @IsBoolean()
  success: boolean;

  /**
   * 에러코드
   *
   * @type {string}
   * @memberof dtoResponse
   */
  @IsString()
  @IsOptional()
  errorCode: string;

  /**
   * 에러 메세지
   *
   * @type {string}
   * @memberof dtoResponse
   */
  @IsString()
  @IsOptional()
  errorMsg: string;

  /**
   * 호출 시간
   *
   * @type {number}
   * @memberof dtoResponse
   */
  @IsNumber()
  timestamp: number;

  /**
   * 호출 시간
   *
   * @type {string}
   * @memberof dtoResponse
   */
  @IsString()
  timeFormat: string;

  /**
   * 상태 코드
   *
   * @type {number}
   * @memberof dtoResponse
   */
  @IsOptional()
  @IsNumber()
  statusCode: number;

  constructor() {
    const t = dayjs();
    this.timestamp = t.valueOf();
    this.timeFormat = t.utc().format();
  }
}

export class ResponseData {}
