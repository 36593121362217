import './App.css';
import 'reflect-metadata';
import { Route, Routes } from 'react-router-dom';
import Index from './pages';
import Mou from './pages/huobi';
import Chainup from './pages/chainup';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />}></Route>
      {/* <Route path="/test" element={<Index />}></Route>*/}
      <Route path="/mou/huobi" element={<Mou />}></Route>
      <Route path="/mou/chainup" element={<Chainup />}></Route>
    </Routes>
  );
}

export default App;
